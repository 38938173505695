import styled from "styled-components";
import { default as Box } from "./SuiBox";

/**
 * Component for showing details of the Company.
 * @components present ViewCompany page and Button
 */

const ListItemWrapper = styled(Box)`
  cursor: pointer;
  &:hover {
    background: linear-gradient(310deg, #ced4da, #ebeff4); {* grey.300 *}
  }
`;

export default ListItemWrapper;
