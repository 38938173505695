import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ButtonComponent from "../../utils/buttons/ButtonComponent";
import { useSelector } from "react-redux";
// import SuiTypography from "components/toolkit/SuiTypography";
import { ActualData } from "components/frequently-used-components/mini-components";

const ModalLayout = ({ showModal, toggle, handleClick }) => {
  const ButtonLabels = useSelector((state) => state.labels.buttonLabels);
  const TextLabels = useSelector((state) => state.labels.textLabels);

  return (
    <Modal isOpen={showModal} toggle={toggle} className="">
      <ModalBody>
        {/* <SuiTypography variant="p">{TextLabels.logout.label}</SuiTypography> */}
        <ActualData
          data={TextLabels.logout.label}
          fontWeight="bold"
          fontSize="1.2rem"
        />
      </ModalBody>
      <ModalFooter>
        <div className="d-flex">
          <div>
            <ButtonComponent
              label={ButtonLabels.logout.label}
              onClick={handleClick}
            />
          </div>
          <div className="ml-4">
            <ButtonComponent
              label={ButtonLabels.cancel.label}
              onClick={toggle}
            />
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalLayout;
