import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import UserReducer from "./user/UserReducer";
import storage from "redux-persist/lib/storage";
import RoleReducer from "./roles/RoleReducer";
// import OnboardDetailReducer from './rookie/onboards/OnboardDetailReducer';
import CompanyDetailReducer from "./company-details/CompanyDetailReducer";
import OrientationReducer from "./rookie/orientation/OrientationReducer";
import DBuserReducer from "./db-user/DBuserReducer";
import TrainingReducer from "./rookie/training/TrainingReducer";
// import OfferLetterDetailReducer from './rookie/offer-letter/OfferLetterDetailReducer';
import LabelsReducer from "./rookie/labels/LabelsReducer";
import WorkflowDetailReducer from "./rookie/workflow/WorkflowDetailReducer";
// import CompensationDetailReducer from './rookie/compensation-details/CompensationDetailReducer';
// import CompanyPolicyDetailReducer from './rookie/company-policy/CompanyPolicyDetailReducer';
// import ScreenersDetailReducer from '../funnel/redux/funnel-screeners/ScreenersDetailReducer';
import UserDashboardReducer from "./userdashboard/UserDashboardReducer";
import FunnelLabelsReducer from "../funnel/redux/funnel-labels/FunnelLabelsReducer";
// import HiringWorkflowDetailReducer from '../funnel/redux/hiring-workflow/HiringWorkflowDetailReducer';
// import FunnelRecruitDetailReducer from '../funnel/redux/funnel-candidate/FunnelRecruitDetailReducer';
// import FunnelWorkflowDetailReducer from '../funnel/redux/funnel-workflow/FunnelWorkflowDetailReducer';
// import StoreMultipleCandidatesDetailReducer from '../funnel/redux/store-multiple-candidates/StoreMultipleCandidatesDetailReducer';
import ReportsDetailReducer from "./reports/ReportsDetailReducer";
import TeamsDetailReducer from "./teams/TeamsDetailReducer";
import RouteReducer from "./routeReducer";
import ErrorReducer from "./errorReducer";
import DomainReducer from "./domainEntities/domainReducer";
import CompanyListReducer from "./company-list-tymeline/companyListReducer";
import APISuccessReducer from "./APISuccessReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "company", "dbUser"],
};

const rootReducer = combineReducers({
  user: UserReducer,
  labels: LabelsReducer,
  role: RoleReducer,
  reports: ReportsDetailReducer,
  // onboards: OnboardDetailReducer,
  company: CompanyDetailReducer,
  companyList: CompanyListReducer,
  orientation: OrientationReducer,
  training: TrainingReducer,
  dbUser: DBuserReducer,
  // issue: OfferLetterDetailReducer,
  // compensation: CompensationDetailReducer,
  // policy: CompanyPolicyDetailReducer,
  workflow: WorkflowDetailReducer,
  funnelLabels: FunnelLabelsReducer,
  // recruits: FunnelRecruitDetailReducer,
  // screener: ScreenersDetailReducer,
  userDashboard: UserDashboardReducer,
  // hiringWorkflow: HiringWorkflowDetailReducer,
  // funnelWorkflow: FunnelWorkflowDetailReducer,
  teams: TeamsDetailReducer,
  // storeMultipleCanidates: StoreMultipleCandidatesDetailReducer,
  route: RouteReducer,
  error: ErrorReducer,
  success: APISuccessReducer,
  domainEntities: DomainReducer,
});

export default persistReducer(persistConfig, rootReducer);
